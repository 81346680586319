  'use strict'
  
  resizeFlg = {}
  resizeFlg.pc = true
  resizeFlg.sp = true
  firstView = ''
  
  rellax = ''
  
  $('.p-movie .js-slide').slick({
    slidesToShow: 3
    variableWidth: true
    centerMode: true
  })
  
  spMenu = ()->
    console.log('menu:SP')
  
  pcMenu = ()->
    console.log('menu:PC')
  
  
  if window.matchMedia('(max-width:750px)').matches
    # SP
    firstView = 'SP'
  
    spMenu()
  else
    # PC
    firstView = 'PC'
  
    pcMenu()
  
  $(window).on('resize',
    ()->
      if window.matchMedia('(max-width:750px)').matches
        if firstView is 'PC' and resizeFlg.sp is true or
        firstView is false and resizeFlg.sp is true
        # PC から SP へ
  
          spMenu()
  
          resizeFlg.pc = true
          resizeFlg.sp = false
          firstView = false
      else
        if firstView is 'SP' and resizeFlg.pc is true or
        firstView is false and resizeFlg.pc is true
        # SP から PC へ
  
          pcMenu()
  
          resizeFlg.pc = false
          resizeFlg.sp = true
          firstView = false
  )
  